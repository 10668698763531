import { Component, OnInit, ViewChild } from '@angular/core';
import { BaliseService } from 'src/app/services/balise.service';
//import { MapsAPILoader } from '@agm/core';
import { GoogleMapsModule, GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps'
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { MessageService } from './../../services/message.service';
import { UserService } from 'src/app/services/user.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-balises',
  templateUrl: './balises.component.html',
  styleUrls: ['./balises.component.scss'],
})

export class BalisesComponent implements OnInit {

  map!: google.maps.Map;

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  
  selectedBaliseTile: number = -1;
  
  markerOptions = {
    origin: {
      opacity: 0,
      infoWindow: '',
    },
    destination: {
      icon: './../../../assets/nri.png',
      size: '30px',
    },
  };

  zoom = 10;

  mapbounds = {
   
  }

  userHospital = "";

  unvIconUrl = './../../../assets/unv.png';
  nriIconUrl = './../../../assets/nri.png';

  public origin = {
    lat: 0,
    lng: 0,
  };
  public destination: any;

  center = {
    lat: 0,
    lng: 0
  }

  cameraMoved = false;

  polylines: any[] = [] ;
  startPoints: any[] = [] ;
  traffic: any[] = [] ;

  balises: any[] = [];

  userPosition: any = {
    lat: 0,
    lng: 0,
  };

  iconUrl = '../../../../assets/small_orange_brain.png';

  userMarkerOptions: any = {
    url: '../../../assets/user_map_icon.png',
    scaledSize: {
      width: 30,
      height: 30,
    },
  };

  passedId: any = null;

  travelsTime: Map<string, BaliseStatusInterface> = new Map<string, BaliseStatusInterface>();
  baliseStatusTypes = BaliseStatus;
  constructor(
    private baliseService: BaliseService,
    //private mapsApiLoader: MapsAPILoader,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private userService: UserService,
  ) {
    this.passedId = this.route.snapshot.queryParamMap.get('id');
  }

  ngOnInit() {
    //document.addEventListener('visibilitychange', this.handleVisibilityChange);
      this.getAllBalises();
    this.userService.getCurrentUser().subscribe(
      (data: any) => {
        if (data?.unv) {
          this.userPosition = {
            lat: data?.unv?.position?.latitude,
            lng: data?.unv?.position?.longitude,
          };
          this.userHospital = "UNV";
          this.map.setCenter(this.userPosition);
          return;
        }

        if (data?.nri) {
          this.userPosition = {
            lat: data?.nri?.position?.latitude,
            lng: data?.nri?.position?.longitude,
          };
          this.userHospital = "NRI";
          this.map.setCenter(this.userPosition);
          return;
        }
        // this.GoogleMapsModule.load().then(() => {
        //   if ('geolocation' in navigator) {
        //     navigator.geolocation.getCurrentPosition((position) => {
        //       this.userPosition = {
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude,
        //       };
        //     });
        //   }
        // });
        
      },
      (error) => {},
    );
    this.baliseService.onBalise().subscribe(
      (data: any) => {
        console.log("reveived data balise");
        
        console.log(data);
        //this.getAllBalises();

        const updatedBaliseIndex = this.balises.findIndex((balise) => balise.id === data.id);
        
        if (updatedBaliseIndex == -1) {
          this.balises.push(data);
          this.travelTimeRender(this.balises[this.balises.length-1]);
        } else {
          this.balises[updatedBaliseIndex] = data;
          this.travelTimeRender(this.balises[updatedBaliseIndex]);
        }
        
        // this.origin = {
        //   lat: data?.position?.latitude,
        //   lng: data?.position?.longitude,
        // };
        if (this.selectedBaliseTile == updatedBaliseIndex) {
          this.polylines = data.polylinesCoordinates.map((point:any) => {return new google.maps.LatLng(point[0], point[1])});
          this.startPoints = data.tabStartPointTraffic;
          this.traffic = data.tabTraffic;
        }

      },
      (err: any) => {
        console.log(err);
      },
    );
    this.baliseService.onBaliseEnd().subscribe(
      (data: any) => {
        this.getAllBalises();
      },
      (err: any) => {
        console.log(err);
      },
    );
    this.baliseService.onBaliseTimeout().subscribe(
      (data: any) => {
        this.travelTimeRender(data);
      },
      (err: any) => {
        console.log(err);
      },
    );
  }

  handleVisibilityChange() {
    if (!document.hidden) {
      // Page is visible, trigger reload
      location.reload();
    }
  }
  async getAllBalises() {
    const result = await firstValueFrom(this.baliseService.getBalises());
    if (result) {
      this.balises = result;
      if (this.passedId !== null) {
        this.setSelectedBaliseTile(
          this.balises.findIndex((balise) => balise?.id === parseInt(this.passedId)),
        );
      }
      this.initializeTravelsTime();
    }
  }

  mapReady(map: google.maps.Map) {
    
    this.map = map;
    map.setOptions({
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    });
    map.setCenter(this.center);
    google.maps.event.addListener(map, "center_changed", function() {
      var center = map.getCenter();
      var latitude = center?.lat();
      var longitude = center?.lng();
      
    });
  }

  zoomChange(event: any) {
    this.zoom = event;
  }

  updateOnCameraMoved(event: any) {
    if (this.selectedBaliseTile!=-1) {
        this.cameraMoved = true;
    }
  }

  async setSelectedBaliseTile(index: number) {
    if (this.selectedBaliseTile === index) {
      this.selectedBaliseTile = -1;
      this.cameraMoved = false;
      return;
    }
    this.selectedBaliseTile = index;
    if (!this.hasPosition(this.balises[index].position)) {
      this.origin = {
        lat: parseFloat(this.balises[index].unv?.position?.latitude),
        lng: parseFloat(this.balises[index].unv?.position?.longitude),
      };
    } else {
      this.origin = {
        lat: parseFloat(this.balises[index]?.position?.latitude),
        lng: parseFloat(this.balises[index]?.position?.longitude),
      };
      this.polylines = this.balises[index]?.polylinesCoordinates.map((point:any) => {return new google.maps.LatLng(point[0], point[1]);});
      this.startPoints = this.balises[index]?.tabStartPointTraffic;
      this.traffic = this.balises[index]?.tabTraffic;

      if (this.polylines.length > 0) {
        this.boundsFromLatLngList(this.polylines);
      } else {
        this.map.setCenter(this.origin);
      }
      
      

      this.center = this.origin;
    }
    this.destination = {
      lat: parseFloat(this.balises[index]?.nri?.position?.latitude),
      lng: parseFloat(this.balises[index]?.nri?.position?.longitude),
    };
  }

  boundsFromLatLngList(list: any[]) {
    let bounds = new google.maps.LatLngBounds;

    list.forEach((marker:any) => {
      bounds.extend(
        marker
      );
    
    });
    // directly can access fitBounds.
    this.map.fitBounds(bounds, 30);

    
  }

  redirectPatient(id: number | null) {
    if (!id) {
      this.router.navigate(['/home/patient/balise/' + this.balises[this.selectedBaliseTile].id]);
    } else {
      this.router.navigate(['/home/patient/' + id]);
    }
  }

  initializeTravelsTime() {
    this.balises.forEach((balise) => {
      this.travelTimeRender(balise);
    });
  }

  travelTimeRender(balise: any) {
    if (balise?.position?.latitude === 0 && balise?.position?.longitude === 0) {
      this.travelsTime.set(balise.id, {
        status: BaliseStatus.WAITING_FOR_TRANSFERT,
        description: 'En attente de transfert',
      });
      return;
    }
    if (balise?.timeout && balise?.active) {
      this.travelsTime.set(balise.id, {
        status: BaliseStatus.COMMUNICATION_ERROR,
        description: 'Erreur de communication',
      });
      return;
    }
    if (balise?.inUNV) {
      if (balise?.active) {
        if (balise?.land) {
          if (balise?.position?.latitude === 0 && balise?.position?.longitude === 0) {
            this.travelsTime.set(balise.id, {
              status: BaliseStatus.WAITING_FOR_COMMUNICATION,
              description: 'En attente de communication',
            });
            return;
          } else {
            this.travelsTime.set(balise.id, {
              status: BaliseStatus.CALCUL,
              description:
                'Arrivée dans ' + balise.remainingTime.textTime + '(avec traffic : ' + balise.remainingTimeInTraffic.textTime + ')',
            });
            // this.baliseService
            //   .getTravelTime(
            //     {
            //       lat: balise?.position?.latitude,
            //       lng: balise?.position?.longitude,
            //     },
            //     {
            //       lat: balise?.nri?.position?.latitude,
            //       lng: balise?.nri?.position?.longitude,
            //     },
            //   )
            //   .then(
            //     (data: any) => {
            //       this.travelsTime.set(balise.id, {
            //         status: BaliseStatus.CALCUL,
            //         description:
            //           'Arrivée dans ' + data?.rows[0]?.elements[0]?.duration_in_traffic?.text,
            //       });
            //     },
            //     (err: any) => {
            //       this.travelsTime.set(balise.id, {
            //         status: BaliseStatus.CANT_CALCULATE,
            //         description: 'On ne peut pas calculer la durée.',
            //       });
            //     },
            //   );
            return;
          }
        } else {
          if (balise?.position?.latitude === 0 && balise?.position?.longitude === 0) {
            this.travelsTime.set(balise.id, {
              status: BaliseStatus.WAITING_FOR_COMMUNICATION,
              description: 'En attente de communication',
            });
            return;
          } else {
            this.travelsTime.set(balise.id, {
              status: BaliseStatus.CALCUL,
              description: this.calculateHelicopterTravelTime(balise),
            });
            return;
          }
        }
      } else {
        this.travelsTime.set(balise.id, {
          status: BaliseStatus.WAITING_FOR_TRANSFERT,
          description: 'En attente de transfert',
        });
        return;
      }
    }
    if (balise?.active && !balise?.timeout) {
      if (balise?.land) {
        if (balise?.position?.latitude === 0 && balise?.position?.longitude === 0) {
          this.travelsTime.set(balise.id, {
            status: BaliseStatus.WAITING_FOR_COMMUNICATION,
            description: 'En attente de communication',
          });
          return;
        } else {
          this.travelsTime.set(balise.id, {
            status: BaliseStatus.CALCUL,
            description:
             'Arrivée dans ' + balise.remainingTime.textTime + '(avec traffic : ' + balise.remainingTimeInTraffic.textTime + ')',
          });
          // this.baliseService
          //   .getTravelTime(
          //     {
          //       lat: balise?.position?.latitude,
          //       lng: balise?.position?.longitude,
          //     },
          //     {
          //       lat: balise?.nri?.position?.latitude,
          //       lng: balise?.nri?.position?.longitude,
          //     },
          //   )
          //   .then(
          //     (data: any) => {
          //       this.travelsTime.set(balise.id, {
          //         status: BaliseStatus.CALCUL,
          //         description:
          //           'Arrivée dans ' + data?.rows[0]?.elements[0]?.duration_in_traffic?.text,
          //       });
          //     },
          //     (err: any) => {
          //       this.travelsTime.set(balise.id, {
          //         status: BaliseStatus.CANT_CALCULATE,
          //         description: 'On ne peut pas calculer la durée.',
          //       });
          //     },
          //   );
          return;
        }
      } else {
        if (balise?.position?.latitude === 0 && balise?.position?.longitude === 0) {
          this.travelsTime.set(balise.id, {
            status: BaliseStatus.WAITING_FOR_COMMUNICATION,
            description: 'En attente de communication',
          });
          return;
        } else {
          this.travelsTime.set(balise.id, {
            status: BaliseStatus.CALCUL,
            description: this.calculateHelicopterTravelTime(balise),
          });
          return;
        }
      }
    }
    if (balise?.inNRI) {
      this.travelsTime.set(balise.id, {
        status: BaliseStatus.ARRIVED,
        description: 'Arrivée',
      });
      return;
    }
    this.travelsTime.set(balise.id, {
      status: BaliseStatus.CALCUL,
      description: 'Arrivée dans ' + balise.remainingTime.textTime + '(avec traffic : ' + balise.remainingTimeInTraffic.textTime + ')',
    });
    
    // this.baliseService
    //   .getTravelTime(
    //     {
    //       lat: balise?.position?.latitude,
    //       lng: balise?.position?.longitude,
    //     },
    //     {
    //       lat: balise?.nri?.position?.latitude,
    //       lng: balise?.nri?.position?.longitude,
    //     },
    //   )
    //   .then(
    //     (data: any) => {
    //       this.travelsTime.set(balise.id, {
    //         status: BaliseStatus.CALCUL,
    //         description: 'Arrivée dans ' + data?.rows[0]?.elements[0]?.duration_in_traffic?.text,
    //       });
    //       return;
    //     },
    //     (err: any) => {
    //       this.travelsTime.set(balise.id, {
    //         status: BaliseStatus.CANT_CALCULATE,
    //         description: 'On ne peut pas calculer la durée.',
    //       });
    //       return;
    //     },
    //   );
    return;
  }

  calculateHelicopterTravelTime(balise: any): string {
    const departureTime = moment(balise?.history[balise?.history.length - 1].departure)
      .tz('Etc/GMT-2+0')
      .toDate();

    const timeHelicopter = moment(balise?.unv?.timeHelicopter * 1000)
      .tz('Etc/GMT-2+0')
      .toDate();

    const arrivalTime = moment(departureTime.getTime() + timeHelicopter.getTime())
      .tz('Etc/GMT-2+0')
      .toDate();
    if (arrivalTime.getTime() < new Date().getTime()) {
      return 'Arrivée';
    }
    const formattedTime = moment(arrivalTime.getTime() - new Date().getTime()).tz('Etc/GMT-2+0');

    if (formattedTime.hour() > 0) {
      return 'Arrivée dans ' + formattedTime.format('HH[h] mm[min]');
    } else {
      return 'Arrivée dans ' + formattedTime.format('mm[min]');
    }
  }

  switchTransportType(baliseIndex: number, mode: boolean) {
    if (this.balises[baliseIndex].land === mode) {
      this.messageService.showMessage('Ce mode de transport est déjà actif');
      return;
    }
    this.baliseService.setTransportType(mode, this.balises[baliseIndex].id).subscribe(
      (data: any) => {
        this.messageService.showMessage('Succès');
        this.getAllBalises();
      },
      (err: any) => {
        this.messageService.showMessage('Erreur lors du changement de mode de transport');
      },
    );
  }

  formatLatestActivity(time: any) {
    return moment(time).tz('Etc/GMT-2').format('HH:mm');
  }

  formatTransfertStart(history: any[], status: BaliseStatus) {
    if (status === BaliseStatus.WAITING_FOR_TRANSFERT) {
      return 'En attente';
    }
    return moment(history[history?.length - 1].departure ?? '')
      .tz('Europe/Paris')
      .format('HH:mm');
  }

  hasPosition(position: any) {
    return parseFloat(position?.latitude) !== 0 && parseFloat(position?.longitude) !== 0;
  }
}

export enum BaliseStatus {
  INITIALIZING,
  ARRIVED,
  CALCUL,
  WAITING_FOR_TRANSFERT,
  WAITING_FOR_COMMUNICATION,
  CANT_CALCULATE,
  COMMUNICATION_ERROR,
  ERROR,
}

export interface BaliseStatusInterface {
  status: BaliseStatus;
  description: string;
}

// *lng lat 0 0 : En attente de transfert

// *deconnected et active : Erreur de communication

// *inUnv et active :
// amublance : 0,0
// attend de communication =>> not 0 0 and google not send : on ne peut pas calculer ;erreur;
// helicopter 0,0
// attend de communication ; calcul countdown si coundown complete ARRIVE sinon erreur

// *inUnv : En attente de transfert

// *inNRI : Arrivée

// *calcule time
