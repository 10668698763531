import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { InputesService } from 'src/app/services/inputes.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private us: UserService,
  ) {}
  code: string = '';
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      // // Access params object here
      // const id = params['id']; // Replace 'id' with the name of the parameter you want to access
      this.code = params['v'];
    });
  }
  password: string = '';
  email: string = '';
  password2: string = '';
  passwordVisible = false;
  passwordVisible2 = false;

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  togglePasswordVisibility2() {
    this.passwordVisible2 = !this.passwordVisible2;
  }
  errors: any = [];

  updatePassword() {
    this.email = this.email.toLocaleLowerCase();
    this.password = this.password;
    if (this.validatePassword(this.password)) {
      this.us
        .changePassword({ email: this.email, password: this.password, code: this.code })
        .subscribe((res) => {
          window.location.href = environment.userURL + '/login';
        });
    }
  }
  login() {
    this.router.navigateByUrl('/');
  }
  landing() {
    this.router.navigateByUrl('/');
  }
  validatePassword(password: any) {
    this.errors = [];
    const minLength = 8;
    const maxLength = 20;
    const regexUpperCase = /[A-Z]/;
    const regexLowerCase = /[a-z]/;
    const regexNumber = /[0-9]/;
    const regexSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (password.length < minLength || password.length > maxLength) {
      this.errors.push("Mot de passe doit être d'au moins 8 caractères");
    }
    if (!regexUpperCase.test(password)) {
      this.errors.push('Le mot de passe doit contenir une lettre majuscule');
    }
    if (!regexLowerCase.test(password)) {
      this.errors.push('le mot de passe doit contenir une lettre minuscule');
    }
    if (!regexNumber.test(password)) {
      this.errors.push('le mot de passe doit contenir un nombre');
    }
    if (!regexSpecial.test(password)) {
      this.errors.push('Le mot de passe doit contenir un caractère spécial');
    }
    if (password !== this.password2) {
      this.errors.push('Les deux mots de passe ne correspondent pas');
    }

    if (this.errors.length) {
      return false;
    } else {
      return true;
    }
  }
}
