<div class="login-container">
  <div class="left">
    <app-side-image></app-side-image>
  </div>
  <div class="container-login-right">
    <app-logo></app-logo>
    <div class="login-form">
      <div class="submit">
        <app-button
          paddingLeft="86px"
          paddingRight="86px"
          borderRadius="10px"
          paddingTop="27px"
          paddingBottom="27px"
          (click)="navigate()"
          text="Se connecter Avec Mot De Passe     "
          icon="exit_to_app"
          class="orange"
        ></app-button>
        <!-- <app-button
          paddingLeft="65px"
          paddingRight="65px"
          paddingTop="27px"
          paddingBottom="27px"
          text="Se connecter Avec Pro Santé Connect"
          borderRadius="10px"
          icon="ecps"
          class="orange"
          (click)="ecps()"
        ></app-button> -->

        <app-button
          paddingLeft="107px"
          paddingRight="107px"
          borderRadius="10px"
          paddingTop="27px"
          paddingBottom="27px"
          (click)="signup()"
          text="Demander Un Compte"
          icon="local_post_office"
          iconColor="iconOrange"
          class="white"
        ></app-button>
        <a
          href="https://iavc.fr/contact/"
          style="
            color: #eb5d2b;
            cursor: pointer;
            font-weight: bold;
            text-decoration: none;
          "
          target="_blank"
        >
          contact IAVC
        </a>
      </div>
    </div>
  </div>
</div>
