import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from 'src/environments/environment';


@Component({
  animations: [
    trigger('fade', [
      transition(':enter', [style({ opacity: 0 }), animate(250, style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate(250, style({ opacity: 0 }))]),
    ]),
  ],
  selector: 'app-test-ecps',
  templateUrl: './test-ecps.component.html',
  styleUrls: ['./test-ecps.component.scss'],
})
export class TestEcpsComponent {
  ngOnInit(): void {
    location.replace(environment.userURL + '/api/auth/login');
  }
}
