import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(private http: HttpClient) {}

  getPatients() {
    return this.http.get<any>(environment.userURL + '/api/patient/hospital');
  }

  getPatient(id: string) {
    return this.http.get<any>(environment.userURL + `/api/patient/${id}`);
  }

  addPatient(patient: any) {
    return this.http.post(environment.userURL + '/api/patient', patient);
  }

  updatePatient(id: string, patient: any) {
    return this.http.patch(environment.userURL + `/api/patient/${id}`, patient);
  }

  deletePatient(id: string) {
    return this.http.delete(environment.userURL + `/api/patient/${id}`);
  }

  getNris() {
    return this.http.get<any>(environment.userURL + '/api/hospital');
  }

  downloadDossier(id: string) {
    return this.http.get(environment.userURL + `/api/patient/form/pdf/${id}`, {
      responseType: 'blob',
    });
  }

  assignNri(id: string, nriId: string) {
    return this.http.patch(environment.userURL + `/api/patient/${id}`, {
      nriId: nriId,
    });
  }

  assignBalise(patientId: string, baliseId: string, description: string) {
    return this.http.patch(environment.userURL + `/api/patient/${patientId}/balise/${baliseId}`, {
      description: description,
    });
  }

  dissociateBalise(baliseId: string) {
    return this.http.patch(environment.userURL + `/api/patient/balise/${baliseId}`, {});
  }
}
