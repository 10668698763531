import { MatModule } from './mat/mat.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonComponent } from './shared/button/button.component';
import { InputComponent } from './shared/input/input.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { LoginComponent } from './pages/login/login.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { LandingComponent } from './pages/landing/landing.component';
import { SideImageComponent } from './shared/side-image/side-image.component';
import { LogoComponent } from './shared/logo/logo.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DialogComponent } from './shared/dialog/dialog.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { BalisesComponent } from './pages/balises/balises.component';
import { ContactsComponent } from './pages/contacts/contacts.component';

import { PatientsComponent } from './pages/patients/patients.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { HomeComponent } from './pages/home/home.component';
import { AuthorizationInterceptor } from './interceptors/authorization-interceptor';
import { PatientDetailsComponent } from './pages/patient-details/patient-details.component';
import { ExportDialogComponent } from './shared/export-dialog/export-dialog.component';
import { PatientAddComponent } from './pages/patient-add/patient-add.component';
import { environment } from 'src/environments/environment';

import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { PatientFormUpdateDeleteDialogComponent } from './shared/patient-form-update-delete-dialog/patient-form-update-delete-dialog.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { PatientErrorDialogComponent } from './shared/patient-error-dialog/patient-error-dialog.component';
import { PatientUpdateAssocierBaliseComponent } from './shared/patient-update-associer-balise/patient-update-associer-balise.component';
import { FormsModule } from '@angular/forms';
//import { AgmCoreModule } from '@agm/core';

//import { AgmDirectionModule } from 'agm-direction';
import { GoogleMapsModule, GoogleMap } from '@angular/google-maps';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TextButtonComponent } from './shared/text-button/text-button.component';
import { IconButtonComponent } from './shared/icon-button/icon-button.component';
import { Socket, SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { NotificationCardComponent } from './shared/notification-card/notification-card.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ConflictCalendarDialogComponent } from './shared/conflict-calendar-dialog/conflict-calendar-dialog.component';
import { EventClickPopupComponent } from './shared/event-click-popup/event-click-popup.component';
import { ContactPopupComponent } from './shared/contact-popup/contact-popup.component';
import { SymptomeChangeDialogComponent } from './shared/symptome-change-dialog/symptome-change-dialog.component';
import { InfoDialogComponent } from './shared/info-dialog/info-dialog.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { SendMailPasswordComponent } from './pages/send-mail-password/send-mail-password.component';
import { Side2Component } from './shared/side2/side2.component';
import { DatePipe } from '@angular/common';
import { TestEcpsComponent } from './pages/test-ecps/test-ecps.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

const config: SocketIoConfig = {
  url: environment.userURL,
  options: {},
};

@NgModule({
  declarations: [
    AppComponent,
    ButtonComponent,
    InputComponent,
    LoginComponent,
    CreateAccountComponent,
    LandingComponent,
    SideImageComponent,
    LogoComponent,
    DialogComponent,
    NotificationComponent,
    NotFoundComponent,
    BalisesComponent,
    ContactsComponent,
    PatientsComponent,
    NavbarComponent,
    SidebarComponent,
    ProfileComponent,
    HomeComponent,
    PatientDetailsComponent,
    ExportDialogComponent,
    PatientAddComponent,
    PatientFormUpdateDeleteDialogComponent,
    ConfirmationDialogComponent,
    PatientErrorDialogComponent,
    PatientUpdateAssocierBaliseComponent,
    TextButtonComponent,
    IconButtonComponent,
    NotificationCardComponent,
    SpinnerComponent,
    ConflictCalendarDialogComponent,
    EventClickPopupComponent,
    ContactPopupComponent,
    SymptomeChangeDialogComponent,
    InfoDialogComponent,
    ForgetPasswordComponent,
    SendMailPasswordComponent,
    Side2Component,
    TestEcpsComponent,
  ],
  imports: [
    MatModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    FormsModule,
    GoogleMapsModule,
    FullCalendarModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    JwtHelperService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
