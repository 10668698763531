<h1>Liste des Balises</h1>
<div class="balise-map-list" *ngIf="!(balises.length > 0)">
  <h2 class="mb-0" style="color: #eb5d2b">Aucune balise trouvée !</h2>
</div>
<div [ngClass]="'balise'">
  <div class="balise-map-list" *ngIf="balises.length > 0">
    <div *ngIf="balises.length > 0" style="margin-bottom: 10px" class="d-flex flex-column gap-2">
      <mat-expansion-panel
        *ngFor="let balise of balises; let i = index"
        [expanded]="selectedBaliseTile === i"
      >
        <mat-expansion-panel-header (click)="setSelectedBaliseTile(i)">
          <div style="display: grid; grid-template-rows: 1fr 1fr">
            <mat-panel-title>
              <mat-icon> dashboard </mat-icon> {{ balise.name ?? 'Undefined' }}
            </mat-panel-title>
            <mat-panel-description>{{
              travelsTime.get(balise.id)?.description
            }}</mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <hr class="m-0 p-0" />
        <div class="d-flex flex-column py-2">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <p>
              Batterie: <span>{{ balise?.batterie }}%</span>
            </p>
            <div class="d-flex flex-column justify-content-between align-items-center">
              <small>Transport</small>
              <div class="d-flex flex-row justify-content-center">
                <button (click)="switchTransportType(i, true)" mat-icon-button>
                  <img [src]="balise?.land ? 'assets/van_filled.svg' : 'assets/van.svg'" />
                </button>
                <div class="vertical-divider m-2"></div>
                <button (click)="switchTransportType(i, false)" mat-icon-button class="m-0 p-0">
                  <img
                    [src]="!balise?.land ? 'assets/helicopter_filled.svg' : 'assets/helicopter.svg'"
                  />
                </button>
              </div>
            </div>
          </div>
          <!-- travelsTime.get(balise.id)?.description -->
          <p>
            Début De Transfert:
            <span>{{
              formatTransfertStart(balise?.history, travelsTime.get(balise.id)?.status!)
            }}</span>
          </p>
          <p *ngIf="balise?.nri">
            Destination:
            <span>
              {{ balise?.nri?.name }}
            </span>
          </p>
          <p *ngIf="travelsTime.get(balise.id)?.status === baliseStatusTypes.COMMUNICATION_ERROR">
            Derniére activité:
            <span>{{ formatLatestActivity(balise?.updatedAt) }}</span>
          </p>
          <!-- <p *ngIf="balise?.unv">Depart: <span>{{balise?.unv?.name}}</span></p> -->
          <div class="d-flex flex-row justify-content-center align-items-center">
            <text-button
              (click)="redirectPatient(balise?.patient?.id ?? null)"
              [outlined]="true"
              [text]="balise?.patient ? 'Dossier patient' : 'Associer patient'"
            ></text-button>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="balise-map">
    
    <google-map
     
      [disableDefaultUI]="true"
      [usePanning]="false"
      [zoom]="zoom"
      [restriction]="mapbounds"
      (mapInitialized)="mapReady($event)"
      [zoomControl]="false"
      (zoomChange)="zoomChange($event)"
      (mapMousemove)="updateOnCameraMoved($event)"
      #mapInstance="googleMap"
      
    >
      <div *ngIf="balises.length >= 0; else emptyActifBalises">
        <div *ngFor="let balise of balises; let i = index">
          <map-marker
            [position]=" {
              lat: hasPosition(balise?.position)
                ? balise?.position?.latitude
                : balise?.unv?.position?.latitude,
              lng: hasPosition(balise?.position)
              ? balise?.position?.longitude
              : balise?.unv?.position?.longitude
            }"
            [icon]="{url: hasPosition(balise?.position) ? iconUrl : unvIconUrl}"
            [options]="{title:  balise?.unv?.name}"
          >
          
            <!-- <map-directions-renderer
              *ngIf="selectedBaliseTile !== -1"
              [renderOptions]="{
                suppressMarkers: true,
                preserveViewport: true
              }"
              [markerOptions]="markerOptions"
              [origin]="origin"
              [destination]="destination"
            >
            </map-directions-renderer> -->
            <ng-container *ngIf="selectedBaliseTile !== -1">
              <ng-container *ngFor="let startPoint of startPoints;let i=index">
              <map-polyline  
                [path]="(i==startPoints.length-1) ? polylines.slice(startPoint, polylines.length) : polylines.slice(startPoint, startPoints[i+1]+1)"
                [options]="{
                  strokeColor: traffic[i]=='NORMAL' ? '#4CAF50' : traffic[i]=='SLOW' ? '#FF9800' : '#F44336'}"
              ></map-polyline>
            </ng-container>
            </ng-container>
          </map-marker>
          
          <!-- Balise UNV -->
          <map-marker
            *ngIf="(selectedBaliseTile !== -1 && balise?.unv) || userHospital=='UNV'"
            [position]="{lat:balise?.unv?.position?.latitude, lng: balise?.unv?.position?.longitude}"
            [icon]="{url: unvIconUrl}"
            [options]="{title:  balise?.unv?.name}"
          >
          </map-marker>
          <!-- Balise NRI -->
          <map-marker
            *ngIf="(selectedBaliseTile !== -1 && balise?.nri) || userHospital=='NRI'"
            [position]="{lat: balise?.nri?.position?.latitude, lng: balise?.nri?.position?.longitude}"
            [icon]="{url: nriIconUrl}"
            [options]="{title: balise?.nri?.name}"
          >
          </map-marker>
        </div>
        <map-marker
          *ngIf="selectedBaliseTile !== -1"
          [position]="{lat: balises[selectedBaliseTile].unv?.position?.latitude, lng: balises[selectedBaliseTile].unv?.position?.longitude}"
          [icon]="{url: unvIconUrl}"
          [options]="{title: balises[selectedBaliseTile].unv?.name}"
        >
        </map-marker>
      </div>
      <ng-template #emptyActifBalises>
        <!-- <map-marker
          [position]="{lat: userPosition?.lat, lng: userPosition?.lng}"
          [icon]="{url: userMarkerOptions}"
        ></map-marker> -->
      </ng-template>
    </google-map>
  </div>
</div>
