import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  getCurrentUser() {
    return this.http.get(environment.userURL + '/api/user/current');
  }
  updateCurrentUser(newUser: any) {
    return this.http.patch(environment.userURL + '/api/user', newUser);
  }
  forget(data: any) {
    return this.http.post(environment.userURL + '/api/forget', data);
  }
  changePassword(data: any) {
    return this.http.post(environment.userURL + '/api/auth/changePassword', data);
  }
  getEvents() {
    return this.http.get(environment.userURL + '/api/hospital/calendar');
  }
  addEvent(event: any) {
    return this.http.post(environment.userURL + '/api/hospital/calendar', event);
  }
  deleteEvent(id: any) {
    return this.http.delete(environment.userURL + '/api/hospital/calendar/' + id);
  }
  getEventById(id: any) {
    return this.http.get(environment.userURL + '/api/hospital/calendar/' + id);
  }
  updateEvent(id: any, data: any) {
    data.conflict = 'delete';
    return this.http.post(environment.userURL + '/api/hospital/calendar/', data);
  }
}
