import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { InputesService } from 'src/app/services/inputes.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(
    private is: InputesService,
    private auth: AuthService,
    private router: Router,
  ) {}
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  error: any = '';
  password: string = '';
  email: string='';
  passwordVisible = false;
  publicKey: string = '';
  erroro: any;

  ngOnInit(): void {}
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  login() {
    this.is.loginForm.email = this.email.toLocaleLowerCase();
    this.is.loginForm.password = this.password;
    console.log("login");
    this.auth.login(this.is.loginForm).subscribe(
      (res: any) => {
        console.log(res);
        if (res.access_token) {
          localStorage.setItem('token', res.access_token);
          this.router.navigateByUrl('/home/balises');
        }
      },
      (err) => {
        this.error = 'mauvais eMail ou mot de passe';
      },
    );
  }
  ecps() {
    this.auth.loginEcps().subscribe((data) => {});
  }
  signup() {
    this.router.navigateByUrl('/signup');
  }
  forget() {
    this.router.navigateByUrl('/sendEmailPassword');
  }
  landing() {
    this.router.navigateByUrl('/');
  }
}
