import { Component, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as moment from 'moment';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import { ContactPopupComponent } from '../contact-popup/contact-popup.component';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  value = '';
  user: any;
  tdy: any = moment(new Date()).format('DD-MM-YYYY');
  dayEvents: any;

  constructor(
    private dialog: MatDialog,
    private us: UserService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public entryData: any,
  ) {
    this.value = this.user?.nri.name || this.user?.unv.name;
  }
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridFourWeek',
    views: {
      dayGridFourWeek: {
        type: 'dayGrid',
        duration: { weeks: 2 },
      },
    },
    dateClick: (info: any) => {
      this.tdy = info.dateStr.slice(-2);
      this.filter(info.dateStr);
      var divs = document.getElementsByClassName('fc-daygrid-day');

      for (var i = 0; i < divs.length; i++) {
        // Get all children with the class name "hali"
        var children = divs[i].getElementsByClassName('hali');

        // Convert the HTMLCollection to an array for easier iteration
        var childrenArray = Array.from(children);

        // Loop through each child element
        childrenArray.forEach(function (child) {
          // Remove the child element
          child.remove();
        });
      }

      var element = info.dayEl;
      element.style.position = 'relative';
      element.style.overflow = 'hidden';

      // Create top border
      var topBorder = document.createElement('div');
      topBorder.classList.add('hali');
      topBorder.style.position = 'absolute';
      topBorder.style.top = '0';
      topBorder.style.left = '0';
      topBorder.style.width = '100%';
      topBorder.style.height = '2px';
      topBorder.style.backgroundColor = '#007FFF';
      element.appendChild(topBorder);

      // Create bottom border
      var bottomBorder = document.createElement('div');
      bottomBorder.classList.add('hali');

      bottomBorder.style.position = 'absolute';
      bottomBorder.style.bottom = '0';
      bottomBorder.style.left = '0';
      bottomBorder.style.width = '100%';
      bottomBorder.style.height = '2px';
      bottomBorder.style.backgroundColor = '#007FFF';
      element.appendChild(bottomBorder);

      // Create left border
      var leftBorder = document.createElement('div');
      leftBorder.classList.add('hali');

      leftBorder.style.position = 'absolute';
      leftBorder.style.top = '0';
      leftBorder.style.left = '0';
      leftBorder.style.width = '2px';
      leftBorder.style.height = '100%';
      leftBorder.style.backgroundColor = '#007FFF';
      element.appendChild(leftBorder);

      // Create right border
      var rightBorder = document.createElement('div');
      rightBorder.classList.add('hali');

      rightBorder.style.position = 'absolute';
      rightBorder.style.top = '0';
      rightBorder.style.right = '0';
      rightBorder.style.width = '2px';
      rightBorder.style.height = '100%';
      rightBorder.style.backgroundColor = '#007FFF';
      element.appendChild(rightBorder);
    },
    events: [],
    eventColor: '#eb5d2b',
    height: 300,
    locale: 'fr',
  };
  checkTime(i: any) {
    return i < 10 ? '0' + i : i;
  }
  today: any = new Date(new Date().getTime() + 30 * 60 * 1000 * 16);
  n: any = this.entryData?.time + 'T' + '08' + ':' + '00';
  m: any = this.entryData?.end ? formatDate(this.entryData?.end,'YYYY-MM-ddTHH:mm', 'fr'): this.entryData?.time + 'T' + '16' + ':' + '00';
  s: any = this.entryData?.start ? formatDate(this.entryData?.start,'YYYY-MM-ddTHH:mm', 'fr'): null;
  en: any = this.entryData?.end ? formatDate(this.entryData?.end,'YYYY-MM-ddTHH:mm', 'fr'): null;
  allEvents: any;
  addEnd: any;
  ngOnInit(): void {
    if (this.entryData.use === 'Profil') {
      this.onChangeInputAdd(this.n);
    }
    // if (this.entryData.user.nri !== null) {
    //   this.value = this.entryData.user.nri.name + ' (NRI)';
    // } else if (this.entryData.user.unv !== null) {
    //   this.value = this.entryData.user.unv.name + ' (UNV)';
    // }
    // this.s = this.entryData.start;
    // this.m = this.entryData.end;

    // this.us.getCurrentUser().subscribe((res: any) => {
    //   this.user = res;
    // });
    if (this.entryData.use === 'Profil' && this.entryData.id) {
      this.us.getEventById(this.entryData.id).subscribe((data: any) => {
        if (data) {
          this.allEvents = data;

          var arr = [];
          var today = [];
          for (var i = 0; i < data.shifts.length; i++) {
            //if (data.days[i].shifts.length) {
              //for (var j = 0; j < data.days[i].shifts.length; j++) {
                arr.push({
                  //day: data.shifts[i].start,
                  id: data.shifts[i].id,
                  start: data.shifts[i].start,
                  end: data.shifts[i].end,
                  trimedstart: moment(data.shifts[i].start).format('HH:mm'),
                  trimedend: moment(data.shifts[i].end).format('HH:mm'),
                  title: '',
                });
              //}
              if (moment(new Date()).format('DD-MM-YYYY') === moment(new Date(data.shifts[i].start)).format('DD-MM-YYYY')) {
                //for (var j = 0; j < data.days[i].shifts.length; j++) {
                  today.push({
                    id: data.shifts[i].id,
                    //day: data.shifts[i].start,
                    start: data.shifts[i].start,
                    end: data.shifts[i].end,
                    trimedstart: moment(data.shifts[i].start).format('HH:mm'),
                    trimedend: moment(data.shifts[i].end).format('HH:mm'),
                    title: 'work',
                  });
                //}
              }
            //}
          }
          this.calendarOptions.events = arr;
          this.dayEvents = today;
        } else {
          this.dayEvents = [];
          this.calendarOptions.events = [];
          this.allEvents = [];
        }
      });
    }
  }

  onChangeInput(e: any) {
    var tawa = new Date(e);
    tawa.setHours(tawa.getHours() + 9);
    this.m = formatDate(tawa,'YYYY-MM-ddTHH:mm', 'fr');
  }
  onChangeInputAdd(e: any) {
    var tawa = new Date(e);
    tawa.setHours(tawa.getHours() + 9);
    this.addEnd = formatDate(tawa,'YYYY-MM-ddTHH:mm', 'fr');
  }
  correctDate(date: any, shift: any) {
    const [day, month, year] = date.split('-');
    let originalDate = new Date(+year, month - 1, +day);
    let shiftDate = new Date(shift);

    shiftDate.setFullYear(originalDate.getFullYear());
    shiftDate.setMonth(originalDate.getMonth());
    shiftDate.setDate(originalDate.getDate());

    return shiftDate;
  }
  close() {
    this.dialogRef.close();
  }
  closeB() {
    this.dialogRef.close({ data : "login" });
  }
  addEvent(start: any, end: any) {
    console.log("start"+new Date(start).toISOString());
    console.log("end"+end);
    start = new Date(start).toISOString();
    end = new Date(end).toISOString();
    this.dialogRef.close({ data: { start, end } });
  }
  choice(choice: any) {
    this.dialogRef.close({ choice });
  }
  updateEvent(method: any, start: any, end: any) {
    if (method === 'DELETE') {
      this.dialogRef.close({ method });
    } else if (method === 'PUT') {
      start = new Date(start).toISOString();
      end = new Date(end).toISOString();
      this.dialogRef.close({ method, data: { start, end } });
    }
  }
  contactPhone(number: any) {
    const dialogRef = this.dialog.open(ContactPopupComponent, {
      width: '200px',
      height: '100px',

      data: { use: 'contact', number },
    });
  }
  sendMail(data: any) {
    window.open(
      'mailto:' + data.email + '?cc=' + '' + '&subject=' + 'Contact Romain' + '&body=' + '',
    );
  }
  filter(day: any) {
    var today = [];
    for (var i = 0; i < this.allEvents.days.length; i++) {
      if (this.allEvents.days[i].shifts.length) {
        if (moment(day).format('DD-MM-YYYY') === this.allEvents.days[i].day) {
          for (var j = 0; j < this.allEvents.days[i].shifts.length; j++) {
            today.push({
              id: this.allEvents.days[i].shifts[j].id,
              day: this.allEvents.days[i].day,
              start: this.correctDate(
                this.allEvents.days[i].day,
                moment(this.allEvents.days[i].shifts[j].start),
              ),
              end: this.correctDate(
                this.allEvents.days[i].day,
                moment(this.allEvents.days[i].shifts[j].end),
              ),
              trimedstart: moment(this.allEvents.days[i].shifts[j].start).format('HH:mm'),
              trimedend: moment(this.allEvents.days[i].shifts[j].end).format('HH:mm'),
              title: '',
            });
          }
        }
      }
    }

    this.dayEvents = today;
  }
}
