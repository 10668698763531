import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { InputesService } from 'src/app/services/inputes.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as moment from 'moment';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import { ConflictCalendarDialogComponent } from 'src/app/shared/conflict-calendar-dialog/conflict-calendar-dialog.component';
import { EventClickPopupComponent } from 'src/app/shared/event-click-popup/event-click-popup.component';
import { InfoDialogComponent } from 'src/app/shared/info-dialog/info-dialog.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  constructor(
    private us: UserService,
    private is: InputesService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
  ) {}

  asp: any;
  np: any;
  tdy: any = moment(new Date()).format('DD-MM-YYYY');
  value = '';
  dayHtml: any;
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridFourWeek',
    views: {
      dayGridFourWeek: {
        type: 'dayGrid',
        duration: { weeks: 2 },
        locale: 'fr',
      },
    },
    dateClick: (info: any) => {
      this.dayHtml = info.dayEl;
      // to finish
      
      this.tdy = moment(new Date(info.dateStr)).format('DD-MM-YYYY');
      
      this.filter(info.dateStr);
      var divs = document.getElementsByClassName('fc-daygrid-day');

      for (var i = 0; i < divs.length; i++) {
        // Get all children with the class name "hali"
        var children = divs[i].getElementsByClassName('hali');

        // Convert the HTMLCollection to an array for easier iteration
        var childrenArray = Array.from(children);

        // Loop through each child element
        childrenArray.forEach(function (child) {
          // Remove the child element
          child.remove();
        });
      }

      var element = info.dayEl;
      element.style.position = 'relative';
      element.style.overflow = 'hidden';

      // Create top border
      var topBorder = document.createElement('div');
      topBorder.classList.add('hali');
      topBorder.style.position = 'absolute';
      topBorder.style.top = '0';
      topBorder.style.left = '0';
      topBorder.style.width = '100%';
      topBorder.style.height = '2px';
      topBorder.style.backgroundColor = '#007FFF';
      element.appendChild(topBorder);

      // Create bottom border
      var bottomBorder = document.createElement('div');
      bottomBorder.classList.add('hali');

      bottomBorder.style.position = 'absolute';
      bottomBorder.style.bottom = '0';
      bottomBorder.style.left = '0';
      bottomBorder.style.width = '100%';
      bottomBorder.style.height = '2px';
      bottomBorder.style.backgroundColor = '#007FFF';
      element.appendChild(bottomBorder);

      // Create left border
      var leftBorder = document.createElement('div');
      leftBorder.classList.add('hali');

      leftBorder.style.position = 'absolute';
      leftBorder.style.top = '0';
      leftBorder.style.left = '0';
      leftBorder.style.width = '2px';
      leftBorder.style.height = '100%';
      leftBorder.style.backgroundColor = '#007FFF';
      element.appendChild(leftBorder);

      // Create right border
      var rightBorder = document.createElement('div');
      rightBorder.classList.add('hali');

      rightBorder.style.position = 'absolute';
      rightBorder.style.top = '0';
      rightBorder.style.right = '0';
      rightBorder.style.width = '2px';
      rightBorder.style.height = '100%';
      rightBorder.style.backgroundColor = '#007FFF';
      element.appendChild(rightBorder);
    },
    eventClick: (info) => {
      console.log(info.event);
    },
    events: [],
    eventColor: '#eb5d2b',
    height: 300,
    locale: 'fr',
  };

  user: any;
  events: any;
  dayEvents: any;
  allEvents: any;
  specialities: any = '';
  ngOnInit(): void {
    this.us.getCurrentUser().subscribe((res) => {
      this.user = res;
      for (var i = 0; i < this.user.specialities.length; i++) {
        if (i < this.user.specialities.length - 1) {
          this.specialities += this.user.specialities[i].name + ', ';
        } else {
          this.specialities += this.user.specialities[i].name;
        }
      }
      this.user.sp = this.specialities;
      this.asp = this.user.notificationTime / 60;
      this.np = this.user.notificationTimeReturn / 60;

      if (this.user.unv === null) {
        this.value = this.user?.nri.name;
      } else {
        this.value = this.user?.unv.name;
      }
    });
    this.us.getEvents().subscribe((data: any) => {
      this.allEvents = data;
      var arr = [];
      var today = [];
      for (var i = 0; i < data.shifts.length; i++) {
        //if (data.days[i].shifts.length) {
          //for (var j = 0; j < data.days[i].shifts.length; j++) {
            arr.push({
              day: moment(data.shifts[i].start).format('DD-MM-YYYY'),
              id: data.shifts[i].id,
              start: data.shifts[i].start,
              end: data.shifts[i].end,
              trimedstart: moment(data.shifts[i].start).format('HH:mm'),
              trimedend: moment(data.shifts[i].end).format('HH:mm'),
              title: '',
            });
          //}
          if (moment(new Date()).format('DD-MM-YYYY') === moment(new Date(data.shifts[i].start)).format('DD-MM-YYYY')) {
            //for (var j = 0; j < data.days[i].shifts.length; j++) {
              today.push({
                id: data.shifts[i].id,
                day: moment(data.shifts[i].start).format('DD-MM-YYYY'),
                start: data.shifts[i].start,
                end: data.shifts[i].end,
                trimedstart: moment(data.shifts[i].start).format('HH:mm'),
                trimedend: moment(data.shifts[i].end).format('HH:mm'),
                title: 'work',
              });
            }
          }
        //}
      //}
      this.calendarOptions.events = arr;
      this.dayEvents = today;
    });
  }

  incDec(name: any, op: any) {
    if (name === 'asp') {
      if (op === '+') {
        this.asp++;
      } else if (op === '-' && this.asp > 1) {
        this.asp--;
      }
    }
    if (name === 'np') {
      if (op === '+') {
        this.np++;
      } else if (op === '-' && this.np > 1) {
        this.np--;
      }
    }
  }

  adjustEventTime(event: any): any {
    const adjustedEvent = { ...event }; // Clone the event object to avoid mutating the original one

    // Parse the start and end times as Date objects
    const start = new Date(adjustedEvent.start);
    const end = new Date(adjustedEvent.end);
    console.log(event);
    // Subtract the appropriate hours
    start.setUTCHours(start.getUTCHours());
    end.setUTCHours(end.getUTCHours());

    // Format the Date objects back into strings
    adjustedEvent.start = this.datePipe.transform(start, 'yyyy-MM-ddTHH:mm:ss.SSS') + 'Z';
    adjustedEvent.end = this.datePipe.transform(end, 'yyyy-MM-ddTHH:mm:ss.SSS') + 'Z';

    return event;
  }
  info() {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '430',
      height: '200',
      data: {},
    });
  }
  eventClick(event: any) {
    const dialogRef = this.dialog.open(EventClickPopupComponent, {
      width: '430',
      height: '200',
      data: {},
    });
    console.log(event);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'modifier') {
        console.log("start"+event.start);
        console.log("end"+event.end);
        const dialogRefs = this.dialog.open(DialogComponent, {
          width: '500px',
          data: {
            use: 'updateEvent',
            id: event.id,
            start: event.start,
            end: event.end,
          },
        });
        dialogRefs.afterClosed().subscribe((res: any) => {
          if (res.method === 'PUT') {
            this.us
              .updateEvent(
                event.id,
                this.adjustEventTime({
                  id: event.id,
                  day: moment(res.data.start).format('DD-MM-YYYY'),
                  start: res.data.start,
                  end: res.data.end,
                  conflict: 'delete',
                }),
              )
              .subscribe((response) => {
                this.ngOnInit();
              });
          }
        });
      }

      if (result === 'supprimer') {
        this.us.deleteEvent(event.id).subscribe((res) => {
          this.ngOnInit();
        });
      }
    });
  }
  filter(day: any) {
    var today = [];
    for (var i = 0; i < this.allEvents.shifts.length; i++) {
      //if (this.allEvents.days[i].shifts.length) {
        if (moment(day).format('DD-MM-YYYY') === moment(this.allEvents.shifts[i].start).format('DD-MM-YYYY')) {
          //for (var j = 0; j < this.allEvents.days[i].shifts.length; j++) {
            today.push({
              // day : this.allEvents.days[i].day,
              id: this.allEvents.shifts[i].id,
              day: moment(this.allEvents.shifts[i].start).format('DD-MM-YYYY'),
              start: moment(this.allEvents.shifts[i].start),
              end: moment(this.allEvents.shifts[i].end),
              trimedstart: moment(this.allEvents.shifts[i].start).format('HH:mm'),
              trimedend: moment(this.allEvents.shifts[i].end).format('HH:mm'),
              title: '',
            });
          //}
        }
      //}
    }
    this.dayEvents = today;
  }

  openConflictDialog(event: any) {
    const dialogRef = this.dialog.open(ConflictCalendarDialogComponent, {
      width: '50%',
      height: '50%',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'merge') {
        this.us
          .addEvent(
            this.adjustEventTime({
              day: moment(event.start).format('DD-MM-YYYY'),
              start: new Date(event.start).toISOString(),
              end: new Date(event.end).toISOString(),
              conflict: 'merge',
            }),
          )
          .subscribe((response) => {
            this.ngOnInit();
          });
      }

      if (result === 'replace') {
        this.us
          .addEvent(
            this.adjustEventTime({
              day: moment(event.start).format('DD-MM-YYYY'),
              start: new Date(event.start).toISOString(),
              end: new Date(event.end).toISOString(),
              conflict: 'replace',
            }),
          )
          .subscribe((response) => {
            this.ngOnInit();
          });
      }
    });
  }

  addEvent() {
    
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      height: '450px',
      data: { use: 'Profil', time: moment(new Date(Number(this.tdy.slice(6,10)),Number(this.tdy.slice(3,5))-1,Number(this.tdy.slice(0,2)))).format('YYYY-MM-DD') },
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.data) {
        this.us
          .addEvent(
            this.adjustEventTime({
              day: moment(res.data.start).format('DD-MM-YYYY'),
              start: new Date(res.data.start).toISOString(),
              end: new Date(res.data.end).toISOString(),
            }),
          )
          .subscribe(
            (response) => {
              this.ngOnInit();
            },
            (err) => {
              this.openConflictDialog({
                day: moment(res.data.start).format('DD-MM-YYYY'),
                start: new Date(res.data.start).toISOString(),
                end: new Date(res.data.end).toISOString(),
              });
            },
          );
      }
    });
  }

  Enregistrer() {
    this.is.profilForm.notificationTime = this.asp * 60;
    this.is.profilForm.notificationTimeReturn = this.np * 60;
    this.us.updateCurrentUser(this.is.profilForm).subscribe((res) => {
      this.ngOnInit();
    });
  }
  salah(date: string) {
    // Input date without time zone offset
    const inputDate = date;

    // Parse input date using Moment.js
    const parsedDate = moment(inputDate, 'ddd MMM DD YYYY HH:mm:ss Z');

    // Convert to UTC
    const utcDate = parsedDate.utc();
    return utcDate.format();
  }
  correctDate(date: any, shift: any) {
    const [day, month, year] = date.split('-');
    let originalDate = new Date(+year, month - 1, +day);
    let shiftDate = new Date(shift);

    shiftDate.setFullYear(originalDate.getFullYear());
    shiftDate.setMonth(originalDate.getMonth());
    shiftDate.setDate(originalDate.getDate());
    return shiftDate;
  }
}
